import React, { useState, useEffect } from "react";
import WordsService from "../services/words.services";

import IdleTimer from 'react-idle-timer'
import Recorder from '../components/Recorder'
import { useHistory } from "react-router-dom";

import moment from 'react-moment';

const Word = (props) => {

    let idleTimer = null;

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [wordId, setWorId] = useState(props.match.params.id);
    const [word, setWord] = useState();
    const [nextWordId, setNextWordId] = useState();
    const [error, setError] = useState();

    const [loadaing, setLoading] = useState(false);
    const [haveRec, setHaveRec] = useState(false);
    const [blob, setBlob] = useState('');
    const [timer, setTimer] = useState(0)
    const [activeTimer, setTimeObject] = useState(null)
    const history = useHistory();

    const getCurrentWord = () => {
        WordsService.currentWord(user.userId, wordId).then(function (response_word) {
            if (response_word.errorMessage) {
                setError(response_word.errorMessage)
            } else {
                setWord(response_word.word[0])
                if(parseInt(response_word.word[0].time) > 0){
                    setTimer(parseInt(response_word.word[0].time))
                } else {
                    setTimer(0)
                }
            }
        })
    }

    const getNextWord = () => {
        setHaveRec(false)
        WordsService.nextWord(user.userId, wordId).then(function (response_word) {
            if (response_word.errorMessage) {
                setNextWordId(0)
                setError(response_word.errorMessage)
            } else {
                setNextWordId(response_word.word[0].id)
                if(parseInt(response_word.word[0].time) > 0){
                    setTimer(parseInt(response_word.word[0].time))
                } else {
                    setTimer(0)
                }
                // 
                if (response_word.word[0].id) {
                    history.push(`/word/${response_word.word[0].id}`);
                    setWord(response_word.word[0])
                    setWorId(response_word.word[0].id)
                    if(parseInt(response_word.word[0].time) > 0){
                        setTimer(parseInt(response_word.word[0].time))
                    } else {
                        setTimer(0)
                    }
                    // setLoading(true);
                    // window.location.reload();
                }
            }
        })
    }

    const uploadFile = () => {
        setLoading(true);
        WordsService.uploadWord(user.userId, word.id, timer, blob).then(function (response_word) {
            if (response_word.errorMessage) {
                setError(response_word.errorMessage)
            } else {
                setTimeout(() => {
                    getNextWord();
                    setLoading(false);
                }, 500);
            }
        })
    }

    useEffect(() => {
        getCurrentWord();
        handleStart();
    }, []);

    // timer function
    const handleStart = () => {
        startTimer()
    }

    const handlePause = () => {
        stopTimer()
    }

    const handleResume = () => {
        startTimer()
    }

    const handleReset = () => {
        stopTimer()
        setTimer(0)
        startTimer()
    }

    const startTimer = () => {
        if (activeTimer)
            return
        setTimeObject(setInterval(() => {
            setTimer((timer) => timer + 1);
        }, 1000))
    }

    const stopTimer = () => {
        if (!activeTimer)
            return
        clearInterval(activeTimer)
        setTimeObject(null)
    }
    // timer function end

    const saveBlob = (data) => {
        setBlob(data);
        setHaveRec(true);
    };

    const gotoDash = () => {
        history.push(`/dashboard`);
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {loadaing ?
                        <div className="loader-wrapper">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <>
                            <div className="next-word">
                                <small onClick={() => gotoDash()}> <i class="fas fa-long-arrow-alt-left"></i> Back to Dashboard</small>
                                {word ?
                                    <>
                                        <h3>Please record the word: </h3>
                                        <p>{word.wordname}</p>
                                    </>
                                    : <p className="no-words">No Words</p>
                                }
                            </div>
                            {word ?
                                <>
                                    <Recorder parentCallback={saveBlob} filename={word.filename} />
                                    <div className="form-div">
                                        <p>Listen the word and if the recording is good please submit</p>
                                        <div className='timer'>
                                            <p>{timer} seconds</p> {/* here we will show timer */}

                                            <IdleTimer
                                                key="IDLE_TIMER"
                                                ref={ref => { idleTimer = ref }}
                                                timeout={60 * 1000}
                                                onIdle={handlePause}
                                                onActive={handleResume}
                                                debounce={1000}
                                            />
                                        </div>
                                        <button onClick={() => uploadFile()} type="button" class="btn btn-success btn-lg" disabled={!haveRec} >Submit word</button>
                                        <button onClick={() => gotoDash()} type="button" class="btn btn-light btn-lg buttonCancel">cancel</button>
                                    </div>
                                </>
                                : null}
                        </>
                    }
                    {error ?
                        <div class="alert alert-warning mt-4" role="alert">
                            {error}, Go to <a onClick={() => gotoDash()}>Dashboard</a>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

export default Word;