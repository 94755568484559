import React, { useState, useEffect, useRef } from "react";
import WordsService from "../services/words.services";

import { useHistory } from "react-router-dom";


const Dashboard = () => {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [words, setWords] = useState();
  const [error, setError] = useState();

  const history = useHistory();

  const getAllWords = () => {
    WordsService.allWords(user.userId).then(function (words) {
      if (words.errorMessage) {
        setError(words.errorMessage)
      }
      setWords(words.words)
    })
  }

  const goSingleWord = (id) => {
    history.push(`/word/${id}`);
  }

  useEffect(() => {
    getAllWords();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="mt-4 text-center">
            Hello, {user.username}
          </div>
          <div className="table-view mt-4">
            <div className="table-row table-header ">
              <div className="id">
                <p>ID</p>
              </div>
              <div className="wordname">
                <p>Word</p>
              </div>
              <div className="isready">
                <p>Status</p>
              </div>
              <div className="time">
                <p>Time spend</p>
              </div>
              <div className="actions">
                <p>Actions</p>
              </div>
            </div>
            {
              words && words.length > 0
                ? words.map(item =>
                  <div key={item.id} className={`table-row ${item.filename ? "ready" : "notready"}`}>
                    <div className="id">
                      <p>{item.id}</p>
                    </div>
                    <div className="wordname">
                      <p>{item.wordname}</p>
                    </div>
                    <div className="isready">
                      {item.filename ?
                        <span class="badge bg-success"><i class="far fa-check-circle"></i>   Success</span>
                        : <span class="badge  bg-secondary"><i class="fas fa-hourglass-half"></i>   Pending</span>
                      }
                    </div>
                    <div className="time">
                      {item.time ?
                        <p><i class="far fa-clock"></i> {item.time} seconds</p>
                        : <p>0</p>
                      }
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => goSingleWord(item.id)}
                      >
                        <span>Edit</span>
                      </button>
                    </div>
                  </div>
                ) : null
            }

          </div>
          {error ?
            <div class="alert alert-warning mt-4" role="alert">
              {error}
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
