import React, { useState, useEffect } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Recorder = ({ parentCallback, filename }) => {
    console.log(filename);
    const [isRecording, setIsRecording] = useState(false);
    const [blobURL, setBlobURL] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);

    const checkPermission = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                window.localStream = stream; // A
                setIsBlocked(false);
            }).catch(err => {
                console.log(err);
                setIsBlocked(true);
            });
    }

    const start = () => {
        if (isBlocked) {
            alert('Permission Denied, please refresh the page and give microphone permission');
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    setIsRecording(true);
                }).catch((e) => console.error(e));
        }
    };

    const stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob)
                setBlobURL(blobURL);
                parentCallback(blob);
                setIsRecording(false);
            }).catch((e) => console.log(e));
    };

    useEffect(() => {
        checkPermission();
    }, []);

    return (
        <div className="recorder">
            <div className="recorder_controls">
                {isRecording ?
                    <>
                        <button onClick={() => stop()} className={`circle_button ${isRecording ? "active Rec" : ""}`} disabled={!isRecording} >
                            <i class="fas fa-stop-circle"></i>
                        </button>
                    </>
                    :
                    <button onClick={() => start()} className={`circle_button ${!isRecording ? "active" : "Rec"}`} disabled={isRecording} >
                        <i class="fas fa-microphone-alt"></i>
                    </button>
                }


            </div>
            {blobURL ?
                <div className="recorder_player">
                    <audio src={blobURL} controls="controls" />
                </div>
                :
                <>
                {
                    filename!=null ? 
                    <div className="recorder_player">
                        <div><small>Previous recording</small></div>
                        <audio src={`https://record.worddio.eu/recordings/${filename}`} controls="controls" />
                    </div>
                    : <p>Please record the word</p>
                }
               
                </>
               
            }

        </div>
    );
}

export default Recorder;