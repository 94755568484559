import React, { useState, useEffect } from "react";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";

import AuthService from "./services/auth.services";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Word from "./pages/Word";

import logo from "./assets/worddio-logo.png"

import { useHistory } from "react-router-dom";

const App = () => {
    const [currentUser, setCurrentUser] = useState(undefined);
    const history = useHistory();
    const [isBlocked, setIsBlocked] = useState(false);

    const checkPermission = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                window.localStream = stream; // A
                setIsBlocked(false);
            }).catch(err => {
                console.log(err);
                setIsBlocked(true);
            });
    }

    useEffect(() => {
        checkPermission();
    }, []);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);
        }
    }, []);

    const logOut = () => {
        AuthService.logout()
        history.push(`/login`);
    };

    const gotoDash = () => {
        history.push(`/dashboard`);
    }

    return (
        <div className="wrapper">
            <div className="container-fluid mt-12">
                <div className="row">
                    <nav class="navbar navbar-light bg-light">
                        <a onClick={() => gotoDash()}> <img src={logo} alt="Logo" width="250" /> </a>
                        {currentUser ?
                            <button onClick={() => logOut()} class="btn btn-outline-success my-2 my-sm-0" type="submit">Log out</button>
                            : null
                        }
                    </nav>
                </div>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <ProtectedRoute exact path={["/", "/dashboard"]} component={Dashboard} />
                    <ProtectedRoute exact path={`/word/:id`} component={Word} />
                </Switch>
            </div>
            <div class="container-fluid footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <footer class="site-footer" id="colophon">
                                <div class="site-info text-center">
                                    <div class="ab-left">
                                        <p>Worddio © 2021 </p>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;