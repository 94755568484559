import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_BASE_URL;
// const API_URL = 'http://192.168.1.59:5001/api'


const allWords = async (userId) => {

  const data = {
    userId: userId,
  }

  return axios
    .post(API_URL + "/words/allWords", data, { headers: authHeader() })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    }).catch((error) => {
      console.log("error", error);
      return ({
        errorMessage: `Error, ${error}`
      })
    });
};

const currentWord = async (userId, wordId) => {

  const data = {
    userId: userId,
    wordId: wordId
  }

  return axios
    .post(API_URL + "/words/singleWord", data, { headers: authHeader() })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    }).catch((error) => {
      console.log("error", error);
      return ({
        errorMessage: `Error, ${error}`
      })
    });
};

const nextWord = async (userId, wordId) => {
  const data = {
    userId: userId,
    wordId: wordId
  }

  return axios
    .post(API_URL + "/words/nextWord", data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      if (error.response.status > 300) {
        return ({
          errorMessage: 'No more words'
        })
      } else {
        console.log(error);
        return ({
          errorMessage: 'Something went wrong'
        })
      }
    });
};

const uploadWord = async (userId, wordid, timer, blob) => {
  const bodyFormData = new FormData();
  bodyFormData.append('time', timer);
  bodyFormData.append('wordSound', blob);

  return axios
    .put(API_URL + `/words/addWords/${wordid}`, bodyFormData, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("error", error);
      return ({
        errorMessage: `Error, ${error}`
      })
    });
}

export default {
  allWords,
  currentWord,
  nextWord,
  uploadWord
};